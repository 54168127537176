/* windicss layer base */
*, ::before, ::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
}
:root {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
:-moz-ui-invalid {
  box-shadow: none;
}
::moz-focus-inner {
  border-style: none;
  padding: 0;
}
::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
a {
  color: inherit;
  text-decoration: inherit;
}
body {
  margin: 0;
  font-family: inherit;
  line-height: inherit;
}
html {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  line-height: 1.5;
}
input {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 0;
  line-height: inherit;
  color: inherit;
}
img {
  border-style: solid;
  max-width: 100%;
  height: auto;
}
input::placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input:-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
img, audio, embed {
  display: block;
  vertical-align: middle;
}
/* windicss layer components */

/* windicss layer utilities */
.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}
.divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
}
.bg-\[rgba\(255\2c 255\2c 255\2c \.2\)\] {
  --tw-bg-opacity: 0.2;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-\[rgba\(255\2c 255\2c 255\2c \.1\)\] {
  --tw-bg-opacity: 0.1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-\[unset\] {
  --tw-bg-opacity: 1;
  background-color: rgba(unset, var(--tw-bg-opacity));
}
.hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.bg-\[rgba\(0\2c 0\2c 0\2c \.4\)\] {
  --tw-bg-opacity: 0.4;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-\[rgba\(255\2c 255\2c 255\2c \.95\)\] {
  --tw-bg-opacity: 0.95;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
.bg-\[orange\] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 165, 0, var(--tw-bg-opacity));
}
.bg-\[rgba\(0\2c 0\2c 0\2c 0\.2\)\] {
  --tw-bg-opacity: 0.2;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-\[rgba\(255\2c 255\2c 255\2c \.15\)\] {
  --tw-bg-opacity: 0.15;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-gradient-to-br {
  background-image: -o-linear-gradient(top left, var(--tw-gradient-stops));
  background-image: -webkit-gradient(linear, left top, right bottom, from(var(--tw-gradient-stops)));
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}
.bg-gradient-to-r {
  background-image: -o-linear-gradient(left, var(--tw-gradient-stops));
  background-image: -webkit-gradient(linear, left top, right top, from(var(--tw-gradient-stops)));
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.from-\[rgba\(83\2c 0\2c 179\2c 1\)\] {
  --tw-gradient-from: rgba(83, 0, 179, var(--tw-from-opacity, 1));
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.from-\[rgba\(88\2c 7\2c 255\2c 1\)\] {
  --tw-gradient-from: rgba(88, 7, 255, var(--tw-from-opacity, 1));
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.from-\[rgba\(88\2c 7\2c 255\2c 0\.5\)\] {
  --tw-gradient-from: rgba(88, 7, 255, var(--tw-from-opacity, 1));
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.via-\[rgba\(0\2c 3\2c 131\2c 0\.996078431372549\)\] {
  --tw-gradient-stops: var(--tw-gradient-from), rgba(0, 3, 131, var(--tw-via-opacity, 1)), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.via-\[rgba\(79\2c 32\2c 163\2c 0\)\] {
  --tw-gradient-stops: var(--tw-gradient-from), rgba(79, 32, 163, var(--tw-via-opacity, 1)), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.to-\[rgba\(26\2c 0\2c 42\2c 0\.8705882352941177\)\] {
  --tw-gradient-to: rgba(26, 0, 42, var(--tw-to-opacity, 1));
}
.to-blue-600 {
  --tw-gradient-to: rgba(37, 99, 235, var(--tw-to-opacity, 1));
}
.to-\[transparent\] {
  --tw-gradient-to: transparent;
}
.to-\[rgba\(79\2c 32\2c 163\2c 0\)\] {
  --tw-gradient-to: rgba(79, 32, 163, var(--tw-to-opacity, 1));
}
.hover\:bg-opacity-30:hover {
  --tw-bg-opacity: 0.3;
}
.bg-opacity-80 {
  --tw-bg-opacity: 0.8;
}
.hover\:bg-opacity-15:hover {
  --tw-bg-opacity: 0.15;
}
.border-\[rgba\(255\2c 255\2c 255\2c \.2\)\] {
  --tw-border-opacity: 0.2;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}
.border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-md {
  border-radius: 0.375rem;
}
.\.rounded-left-xl {
  border-radius: 0.75rem;
}
.\.rounded-right-xl {
  border-radius: 0.75rem;
}
.\.rounded-right-full {
  border-radius: 9999px;
}
.border-none {
  border-style: none;
}
.border-2 {
  border-width: 2px;
}
.border-1 {
  border-width: 1px;
}
.border-b-1 {
  border-bottom-width: 1px;
}
.border-b-2 {
  border-bottom-width: 2px;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.block {
  display: block;
}
.group:hover .group-hover\:block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.\!flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
}
.inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}
.group:hover .group-hover\:hidden {
  display: none;
}
.hidden {
  display: none;
}
.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex-nowrap {
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.float-right {
  float: right;
}
.font-light {
  font-weight: 300;
}
.font-600 {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.h-10 {
  height: 2.5rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-\[1\.4rem\] {
  font-size: 1.4rem;
  line-height: 1;
}
.text-\[1\.1rem\] {
  font-size: 1.1rem;
  line-height: 1;
}
.text-\[1\.2rem\] {
  font-size: 1.2rem;
  line-height: 1;
}
.leading-\[3rem\] {
  line-height: 3rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mt-4 {
  margin-top: 1rem;
}
.max-h-\[50vh\] {
  max-height: 50vh;
}
.max-h-\[600px\] {
  max-height: 600px;
}
.max-h-\[40vh\] {
  max-height: 40vh;
}
.min-h-\[100vh\] {
  min-height: 100vh;
}
.min-w-\[280px\] {
  min-width: 280px;
}
.opacity-100 {
  opacity: 1;
}
.opacity-45 {
  opacity: 0.45;
}
.opacity-50 {
  opacity: 0.5;
}
.hover\:opacity-100:hover {
  opacity: 1;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-70 {
  opacity: 0.7;
}
.overflow-auto {
  overflow: auto;
}
.p-8 {
  padding: 2rem;
}
.p-6 {
  padding: 1.5rem;
}
.p-4 {
  padding: 1rem;
}
.hover\:p-6:hover {
  padding: 1.5rem;
}
.p-2 {
  padding: 0.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pointer-events-none {
  pointer-events: none;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.top-10 {
  top: 2.5rem;
}
.right-0 {
  right: 0px;
}
.bottom-0 {
  bottom: 0px;
}
.hover\:shadow-md:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 4px 6px -1px rgba(var(--tw-shadow-color), 0.1), 0 2px 4px -1px rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 4px 6px -1px rgba(var(--tw-shadow-color), 0.1), 0 2px 4px -1px rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-xl:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 20px 25px -5px rgba(var(--tw-shadow-color), 0.1), 0 10px 10px -5px rgba(var(--tw-shadow-color), 0.04);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-xl {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 20px 25px -5px rgba(var(--tw-shadow-color), 0.1), 0 10px 10px -5px rgba(var(--tw-shadow-color), 0.04);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:ring-3:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.hover\:ring-2:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.text-center {
  text-align: center;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-\[rgba\(255\2c 255\2c 255\2c \.2\)\] {
  --tw-text-opacity: 0.2;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-\[rgba\(255\2c 255\2c 255\2c \.8\)\] {
  --tw-text-opacity: 0.8;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}
.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}
.text-\[rgba\(255\2c 255\2c 255\2c \.9\)\] {
  --tw-text-opacity: 0.9;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-\[rgba\(255\2c 255\2c 255\2c \.6\)\] {
  --tw-text-opacity: 0.6;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-\[rgba\(255\2c 255\2c 255\2c \.5\)\] {
  --tw-text-opacity: 0.5;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.focus\:text-\[\#666\]:focus {
  --tw-text-opacity: 1;
  color: rgba(102, 102, 102, var(--tw-text-opacity));
}
.hover\:text-\[\#666\]:hover {
  --tw-text-opacity: 1;
  color: rgba(102, 102, 102, var(--tw-text-opacity));
}
.tracking-wider {
  letter-spacing: 0.05em;
}
.tracking-wide {
  letter-spacing: 0.025em;
}
.w-full {
  width: 100%;
}
.w-8 {
  width: 2rem;
}
.w-\[400px\] {
  width: 400px;
}
.w-\[fit-content\] {
  width: fit-content;
}
.w-\[300px\] {
  width: 300px;
}
.w-\[180px\] {
  width: 180px;
}
.w-\[800px\] {
  width: 800px;
}
.z-99 {
  z-index: 99;
}
.transform {
  --tw-rotate: 0;
  --tw-rotate-x: 0;
  --tw-rotate-y: 0;
  --tw-rotate-z: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scale-z: 1;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-translate-z: 0;
  -webkit-transform: rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z));
  -ms-transform: rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z));
  transform: rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z));
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.transition {
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, filter, backdrop-filter;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform, filter, backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-in {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.duration-200 {
  -webkit-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  transition-duration: 200ms;
}
.duration-100 {
  -webkit-transition-duration: 100ms;
  -o-transition-duration: 100ms;
  transition-duration: 100ms;
}
@media (max-width: 1279.9px) {
  .\<xl\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  .\<xl\:flex-wrap {
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media (max-width: 1023.9px) {
  .\<lg\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }
  .\<lg\:rounded-md {
    border-radius: 0.375rem;
  }
  .\<lg\:flex-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .\<lg\:flex-wrap {
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media (max-width: 767.9px) {
  .\<md\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }
  .\<md\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }
  .\<md\:flex-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
}