@import '~antd/dist/antd.css';
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar:horizontal {
  height: 6px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  height: 0;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, .2);
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #378fff;
}
::-webkit-input-placeholder {
  letter-spacing: 1px;
  font-size: .95rem;
  color: #ccc;
}
.fade-enter {
  opacity: 0.01;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-leave {
  opacity: 1;
}
.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
.ant-switch {
  padding: 0 8px !important;
  height: 36px;
  line-height: 36px;
}
.ant-switch .ant-switch-handle {
  top: 8px !important;
  left: 6px;
}
.ant-switch-checked {
  background-color: rgb(86, 8, 246) !important;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 8px);
}
.ant-input-control-area .ant-input-affix-wrapper {
  background-color: transparent !important;
  border: 1px solid rgb(199, 170, 253, 0.5) !important;
  border-radius: 0 !important;
  flex-flow: column;
}
.ant-input-control-area .ant-input-affix-wrapper:before {
  display: none !important;
}
.ant-input-control-area .ant-input-affix-wrapper .ant-input-prefix {
  color: #fff !important;
  padding-bottom: 6px;
  margin-bottom: 6px;
  border-bottom: 1px dashed rgba(255, 255, 255, .4);
}
.ant-input-control-area .ant-input-affix-wrapper .ant-input {
  background-color: transparent !important;
  color: #fff;
  width: 180px;
  text-align: left;
}
.ant-table {
  background: rgb(255, 255, 255, .2) !important;
  border: 2px solid rgba(255, 255, 255, .1);
}
.ant-table .ant-table-thead {
  box-shadow: 0 4px 20px rgba(51, 51, 51, 0.1);
}
.ant-table .ant-table-thead .ant-table-cell {
  background-color: rgb(255, 255, 255, .75) !important;
  border-bottom: 1px solid rgba(177, 177, 177, .4) !important;
  border-left: 1px solid rgba(177, 177, 177, .3) !important;
}
.ant-table .ant-table-thead .ant-table-cell:hover {
  background-color: rgb(255, 255, 255, 1) !important;
}
.ant-table .ant-table-tbody .ant-table-row .ant-table-cell {
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, .2) !important;
}
.ant-table .ant-table-tbody .ant-table-row .ant-table-column-sort {
  background-color: rgba(255, 255, 255, .1);
}
.ant-table .ant-table-tbody .ant-table-row:hover {
  background-color: transparent !important;
}
.ant-table .ant-table-tbody .ant-table-row:hover .ant-table-cell {
  background-color: rgba(255, 255, 255, .15) !important;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
  font-weight: bolder;
}
.ant-table .ant-table-tbody .ant-table-row-expand-icon {
  background-color: transparent !important;
}
.ant-table .ant-table-tbody .ant-table-expanded-row > td {
  background-color: rgba(0, 0, 0, .2) !important;
  color: rgba(255, 255, 255, .8);
}
.ant-table .ant-table-cell-fix-left {
  background-color: rgba(255, 255, 255, .2);
}
.ant-table .ant-table-row-selected {
  background: rgba(255,255,255,.1) !important;
}
.ant-table .ant-table-row-selected td {
  background: unset !important;
}
.ant-message-notice .ant-message-notice-content {
  background-color: rgba(255, 255, 255, .8);
  transform: scale(0.85);
}
.ant-pagination .ant-pagination-item {
  color: #fff;
  opacity: .5;
}
.ant-pagination .ant-pagination-item a {
  color: inherit;
}
.ant-pagination .ant-pagination-item:hover {
  opacity: 1;
}
.ant-pagination .ant-pagination-item-active {
  background-color: rgba(0, 0, 0, .2);
  border-color: rgba(255, 255, 255, .8);
  color: #ffffff;
}
.ant-pagination .ant-pagination-item-active a {
  color: inherit;
}
.ant-select-selector {
  background-color: rgba(0, 0, 0, .2) !important;
  border-color: transparent !important;
  color: rgba(255, 255, 255, .4);
}
.ant-pagination-item-link span {
  color: rgba(255, 255, 255, .4);
}
.ant-radio-group .ant-radio-button-wrapper {
  border: 2px solid rgba(0, 0, 0, .2);
  background-color: rgb(86, 8, 246, .5) !important;
  color: #fff;
}
.ant-radio-group .ant-radio-button-wrapper:before {
  display: none;
}
.ant-radio-group .ant-radio-button {
  background-color: unset !important;
}
.ant-notification .ant-notification-notice {
  background-color: rgba(255, 255, 255, .75) !important;
}
.ant-notification .ant-notification-notice:hover {
  background-color: rgba(255, 255, 255, 1) !important;
}
.ant-select .ant-select-selection-item {
  font-weight: lighter;
  color: rgba(255, 255, 255, .8);
}
.ant-select .ant-select-arrow {
  color: rgba(255, 255, 255, .5);
}
.ant-select .ant-select-selector .ant-select-selection-item {
  border-color: rgba(255, 255, 255, .4);
  background-color: rgba(0, 0, 0, .1);
}
.ant-select .ant-select-selector .ant-select-selection-item .ant-select-selection-item-remove {
  color: rgba(255, 255, 255, .8);
}
.ant-select-dropdown {
  background-color: rgb(86, 8, 246, .8) !important;
  border: 2px solid rgb(86, 8, 246, 1);
  box-shadow: 0 0 16px rgba(0, 0, 0, .8);
}
.ant-select-dropdown .ant-select-item {
  font-weight: lighter !important;
  color: rgba(255, 255, 255, .6);
  background-color: unset !important;
}
.ant-select-dropdown .ant-select-item-option-active {
  color: rgba(255, 255, 255, .8);
}
.ant-modal-root .ant-modal-mask {
  backdrop-filter: blur(4px);
}
.ant-modal-root .ant-modal .ant-modal-content {
  background-color: rgb(86, 8, 246, .5) !important;
}
.ant-modal-root .ant-modal .ant-modal-header {
  background-color: rgb(255, 255, 255, .95);
}
.ant-modal-root .ant-modal .ant-modal-body {
  color: #fff !important;
}
.ant-modal-root .ant-modal .ant-modal-footer {
  border-top: 1px solid rgb(86, 8, 246, 1);
}
.ant-dropdown .ant-table-filter-dropdown {
  background-color: rgba(255, 255, 255, .6);
}
.ant-dropdown .ant-dropdown-menu {
  background-color: rgba(255, 255, 255, .6);
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active {
  color: #000;
  background-color: rgba(255, 255, 255, .8);
}
.withdraw-input {
  background-color: transparent !important;
  color: #fff;
  width: 180px;
  text-align: left;
}